<template>
    <div v-loading="loading">
        <el-row>
            <van-form ref="form">
                <van-field label="传感器序列号" v-model="sensorSerialNumber" placeholder="请输入传感器序列号后六位" name="validator" :rules="[{ required: true, trigger: 'blur', validator: this.validator, message: '请输入后六位' }]"></van-field>
                <van-button type="info" :block="true">查询</van-button>
            </van-form>
        </el-row>
        <div>
            <ul v-if="listData.length > 0">
                <li v-for="item in listData" :key="item.id" class="card">
                    <van-cell-group inset>
                        <van-cell title="序列号：">{{ item.sensorNumber }}</van-cell>
                        <van-cell title="密钥：">{{ item.pwd }}</van-cell>
                    </van-cell-group>
                </li>
            </ul>
            <ul v-if="showNoDataMessage">
                <li class="alert-warning">
                    <div class="card-content">
                        <div class="card-item">无数据</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
    data() {
        return {
            sensorSerialNumber: "",
            listData: [],
            showNoDataMessage: false,
            loading: false,
        };
    },
    methods: {
        validator(val) {
            let that = this;
            if (/^.{6}$/.test(val)) {
                that.handleSearch();
                return true;
            } else {
                return false;
            }
        },
        handleSearch() {
            let that = this;
            that.loading = true;
            that.axios
                .post("WXCW_WXCWSensor/GetList", {
                    sensorNumber: that.sensorSerialNumber,
                    pageSize: 20000,
                })
                .then(function (response) {
                    that.loading = false;
                    that.listData = response.data.data.data;
                    that.showNoDataMessage = that.listData.length === 0;
                });
        },
    },
    mounted() { },
};
</script>

<style scoped>
.card {
    list-style: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #409eff;
}

.alert-warning {
    list-style: none;
    margin: 10px;
    border-radius: 5px;
    background-color: #fff3cd;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.card-item {
    margin: 10px;
    text-align: center;
}
</style>